import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,on,addClass, findAll} from '@elements/dom-utils';

const selectors = {
    base: '.js-cursor',
    interaction: '.js-cursor__interaction'
};

export function init() {
    if (matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    onFind(selectors.base, function (cursor) {
        let interactionElements = findAll(selectors.interaction);

        function update(e) {
            let element = this;

            if(e.type === 'mouseleave') {
                // element.style.cssText = '';
                removeClass('has-hover', cursor);
            } else {
                // let { offsetX: x, offsetY: y } = e,
                //     { offsetWidth: width, offsetHeight: height } = this,
                //     walk = 5,
                //     xWalk = (x / width) * (walk * 1.5) - walk,
                //     yWalk = (y / height) * (walk * 1.5) - walk;

                addClass('has-hover', cursor);
                // element.style.cssText = `transform: translate(${xWalk}px, ${yWalk}px);`;
            }
        }

        function handleCursor(e) {
            let { clientX: x, clientY: y } = e;
            cursor.style.cssText =`left: ${x}px; top: ${y}px;`;
        }

        interactionElements.forEach(b => b.addEventListener('mousemove', update));
        interactionElements.forEach(b => b.addEventListener('mouseleave', update));
        window.addEventListener('mousemove', handleCursor);
    });
}

