"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from '@elements/viewport-utils';
import {addClass, removeClass} from '@elements/dom-utils';

let settings = {
    offset: 100
};

export function init() {
    onFind('.js-img-scroll-zoom', function (baseElement) {
        onEnterViewPort (baseElement, function(element){
            addClass('img-scroll-zoom-in', element);
            element.addEventListener('animationend', () => {
                removeClass('img-scroll-zoom-in', element);
                removeClass('img-scroll-zoom', element);
            });
        }, settings);
    });
}

