"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {
    findIn,
    closest,
    removeClass,
    findAllIn,
    findAll,
    on,
    off,
    getAttribute,
    addClass,
    hasClass,
    setAttribute,
    find
} from '@elements/dom-utils';
import {renderTemplate} from "./render-template";
import Popover from 'bootstrap/js/dist/popover';

const selectors = {
    base: '.js-nav',
    toggleOverlay: '.js-nav__toggle-overlay',
    showOverlay: '.js-nav__show-overlay',
    collapse: '.js-nav__collapse',
    overlay: '.js-nav__overlay',
    baseList: '.js-nav__base-list',
    list: '.js-nav__list',
    listItem: '.js-nav__list-item',
    listToggle: '.js-nav__list-toggle',
    navOverlayWrapper: '.js-nav__overlay-wrapper',
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init() {

    onFind('[data-bs-toggle="popover"]',function (element) {
        new Popover(element);
    });

    let isInFirstViewport = () => window.pageYOffset < (window.innerHeight - 80);

    onFind('.js-nav--scroll-transform', function (nav) {
        let mainNav = find('.js-main-navbar');
        let navIsTransparent = () => hasClass('is-transparent', nav);
        let mainNavToggle = findIn('.js-nav__toggle-overlay--main', nav)
        let navOverlayIsOpen = function(){
            if(mainNavToggle){
               return hasClass('is-open', mainNavToggle);
            }
            return false;
        };

        window.onscroll = function () {
            if (!isInFirstViewport() && navIsTransparent() && !navOverlayIsOpen()) {
                makeNavWhite(mainNav);
            } else if (isInFirstViewport() && !navIsTransparent()) {
                makeNavTransparent(mainNav);
            }
        };
    });

    onFind(selectors.base, function (baseElement) {
        let toggleOverlays = findAll(selectors.toggleOverlay);

        toggleOverlays.forEach(function (toggleOverlay) {

            on('click', function (evt) {
                let allOverlays = findAll('.js-nav__overlay', baseElement);
                let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
                let overlayItem = findIn(targetId, baseElement);
                let renderTemplateItem = findIn('.js-render-template', baseElement);

                if (renderTemplateItem) {
                    renderTemplate(renderTemplateItem);
                }

                let searchInput = findIn('.js-nav__overlay-search-input', overlayItem);
                let transformNav = closest('.js-main-navbar', baseElement);

                //if another overlay is currently opened hide it
                allOverlays.map(function (overlay) {
                    if (hasClass('show-overlay', overlay)) {
                        let targetId = overlay.getAttribute('id');
                        let currentItem = findIn('[data-nav-overlay-target="#' + targetId + '"]', baseElement);
                        if (overlay !== overlayItem) {
                            hideItem(currentItem);
                            hideOverlay(overlay);
                            if (!(isInFirstViewport() && hasClass('js-nav--scroll-transform', transformNav))) {
                                makeNavWhite(transformNav);
                            }
                        }
                    }
                });

                if (hasClass('show-overlay', overlayItem)) {
                    hideItem(toggleOverlay);
                    hideOverlay(overlayItem);
                    removeClass('body--freezed', document.body);
                    removeClass('is-open-navbar', baseElement);
                    if (hasClass('main-navbar-toggle', toggleOverlay)) {
                        if (!(isInFirstViewport() && hasClass('js-nav--scroll-transform', transformNav))) {
                            makeNavWhite(transformNav);
                        }
                    }
                } else {
                    showItem(toggleOverlay);
                    showOverlay(overlayItem);
                    if(isMobile()) {
                        // remove the is-open classes from the level-0 links
                        findAll(".js-nav__list-item").forEach((item) => {
                            if(hasClass("mega-nav__item--level-0", item) && hasClass("is-open", item)) {
                                removeClass("is-open", item)
                            }
                        })
                    }

                    let navOverlayWrapper = findIn(selectors.navOverlayWrapper, baseElement);
                    if (isMobile()) {
                        toggleMobileOverlay(-1, navOverlayWrapper, true); // to reset the position of the mobile overlay to level 0 when opening again
                    }

                    if (searchInput) {
                        focusInput(searchInput);
                    }
                    addClass('body--freezed', document.body);
                    addClass('is-open-navbar', baseElement);

                    if (hasClass('main-navbar-toggle', toggleOverlay) && !hasClass('is-transparent', transformNav)) {
                        makeNavTransparent(transformNav);
                    }
                }

                function focusInput(input) {
                    // iOS needs focusing directly in the click event
                    input.focus();

                    let timeout = setTimeout(function () {
                        /* timeout is needed because some browsers don't allow focusing a element
                        if it is not visible */
                        input.focus();
                        clearTimeout(timeout);
                    }, 500);
                }
                function bodyClickHandler(evt) {
                    if (!closest('.main-navbar', evt.target)) {
                        evt.preventDefault();
                        hideItem(toggleOverlay);
                        hideOverlay(overlayItem);
                        removeClass('body--freezed', document.body);
                        removeClass('is-open-navbar', baseElement);
                    }
                }

                function showOverlay(overlay) {
                    addClass('show-overlay', overlay);
                    setAttribute('aria-expanded', true, overlay);
                    if (!isMobile()) {
                        setTimeout(function () {
                            addBodyClickHandler(bodyClickHandler);
                        }, 300);
                    }
                }

                function hideOverlay(overlay) {
                    removeClass('show-overlay', overlay);
                    setAttribute('aria-expanded', false, overlay);
                    if (!isMobile()) {
                        removeBodyClickHandler(bodyClickHandler);
                    }
                }

            }, toggleOverlay);
        });

        onFind(selectors.baseList, function (list) {
            let itemToggles = findAllIn(selectors.listToggle, list);
            let navOverlayWrapper = findIn(selectors.navOverlayWrapper, baseElement);

            if (itemToggles) {
                itemToggles.map((toggle) => {
                    on('click', function (evt) {
                        evt.preventDefault();
                        let parentItem = closest('.js-nav__list-item', toggle);
                        let allListItems = findAllIn(selectors.listItem, list);
                        let parentList = closest('.js-nav__list', parentItem);
                        let itemLevel = parseInt(toggle.getAttribute('data-nav-level'));
                        let allParentItems = findAllIn('.js-nav__list-item', parentList);

                        if (hasClass('is-open', parentItem) ) {
                            hideItem(parentItem);
                            if(isMobile){
                                toggleMobileOverlay(itemLevel, navOverlayWrapper, false);
                            }
                        } else {
                            if(isMobile){
                                toggleMobileOverlay(itemLevel, navOverlayWrapper, true);
                            }

                            if(!isMobile()){
                                allParentItems.map( (item) => removeClass('is-open', item));
                                allParentItems.map( (item) => removeClass('is-active', item));
                            }

                            if(hasClass('js-nav__base-list', parentList)){
                                hideItems(allListItems);
                            }

                            showItem(parentItem);
                            window.addEventListener("click", function _listener(evt) {
                                if (!parentItem.contains(evt.target)) {
                                    removeClass('is-open', parentItem);

                                    window.removeEventListener("click", _listener, true);
                                }
                            }, true);
                        }
                    }, toggle);
                });
            }
        });

    });
}

function toggleMobileOverlay(level, overlayWrapper, open) {
    if(open){
        level = level+1;
    }
    else{
        level = level-1;
    }
    removeClass('level-0', overlayWrapper);
    removeClass('level-1', overlayWrapper);
    removeClass('level-2', overlayWrapper);
    addClass('level-' + level, overlayWrapper);
}

function makeNavTransparent(nav) {
    let logoWhite = findAllIn('.js-nav__logo-white', nav);
    let logoColor = findAllIn('.js-nav__logo-color', nav);

    addClass('is-transparent', nav);
    logoColor.map(item => {
        removeClass('is-active', item)
    });
    logoWhite.map(item => {
        addClass('is-active', item)
    });
}

function makeNavWhite(nav) {
    let logoWhite = findAllIn('.js-nav__logo-white', nav);
    let logoColor = findAllIn('.js-nav__logo-color', nav);

    removeClass('is-transparent', nav);
    logoWhite.map(item => {
        removeClass('is-active', item)
    });
    logoColor.map(item => {
        addClass('is-active', item)
    });
}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}

function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}

function hideItem(item) {
    removeClass('is-open', item);
    removeClass('is-open--only-desktop', item);
    setAttribute('aria-expanded', false, item);
}

function showItem(item) {
    addClass('is-open', item);
    setAttribute('aria-expanded', true, item);
}

function hideItems(items) {
    items.forEach(function (item) {
        removeClass('is-open', item);
        removeClass('is-open--only-desktop', item);
        setAttribute('aria-expanded', false, item);
    });

}


export function openCartFlyOut() {
    let toggleOverlay = findIn('.js-load-cart', document.body);
    let overlayItem = findIn('.js-nav__overlay--cart', document.body);

    if (hasClass('show-overlay', overlayItem)) {
        hideItem(toggleOverlay);
        hideOverlay(overlayItem);
        removeClass('body--freezed', document.body);
    } else {
        showItem(toggleOverlay);
        showOverlay(overlayItem);
        addClass('body--freezed', document.body);
    }

    function bodyClickHandler(evt) {
        if (!closest('.main-navbar', evt.target)) {
            evt.preventDefault();
            hideItem(toggleOverlay);
            hideOverlay(overlayItem);
            removeClass('body--freezed', document.body);
        }
    }

    function showOverlay(overlay) {
        addClass('show-overlay', overlay);
        setAttribute('aria-expanded', true, overlay);
        if (!isMobile()) {
            setTimeout(function () {
                addBodyClickHandler(bodyClickHandler);
            }, 300);
        }
    }

    function hideOverlay(overlay) {
        removeClass('show-overlay', overlay);
        setAttribute('aria-expanded', false, overlay);
        if (!isMobile()) {
            removeBodyClickHandler(bodyClickHandler);
        }
    }

}