import {on, find, findAllIn, findIn, empty, trigger, closest, removeAttribute} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";

export function init() {
    let defaultUrl;

    onFind('.js-dynamic-modal-toggle', function (element) {
        let modal = find(element.getAttribute('data-bs-target'));

        on('click', function () {
            loadContent(modal, element.getAttribute('data-content-url'));

            defaultUrl = window.location.href;

            if (history.replaceState && element.getAttribute('data-history-url')) {
                history.replaceState(
                    null,
                    document.title,
                    element.getAttribute('data-history-url'),
                );
            }
        }, element);
    });

    onFind('.js-dynamic-modal', function (modal) {
        on('hidden.bs.modal', function () {
            empty(findIn('.js-dynamic-modal__content', modal));

            if (history.replaceState && defaultUrl) {
                history.replaceState(
                    null,
                    document.title,
                    defaultUrl,
                );
            }
        }, modal);
    });

    onFind('.js-dynamic-modal__form', function (modalForm) {
        let modal = closest('.js-dynamic-modal', modalForm);

        let formTrigger = 'form-validation.submit';
        if (modalForm.getAttribute('data-form-trigger')) {
            formTrigger = modalForm.getAttribute('data-form-trigger');
        }

        on(formTrigger, (evt) => {
            loadContent(modal, modalForm.getAttribute('data-action'), new FormData(modalForm), modalForm.getAttribute('method')).then(() => {
                if (modalForm.getAttribute('data-form-reload')) {
                    window.location.reload();
                }
            });
        }, modalForm);
    });
}

export function loadContent(modal, url, data, method) {
    let pendingRequest;

    if (method === 'GET') {
        pendingRequest = axios({method: 'GET', url: url, params: data });
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: data });
    }

    asyncAppend({
        target: findAllIn('.js-dynamic-modal__content', modal),
        loading: findAllIn('.js-dynamic-modal__loading', modal),
        options: {
            allowScriptTags: true,
        }
    }, pendingRequest.then((x) => x.data));

    return pendingRequest;
}
