import {find, on} from '@elements/dom-utils';
import Modal from 'bootstrap/js/dist/modal';
import {onFind} from "@elements/init-modules-in-scope";

const selectors = {
    base: '.js-darkpage',
};

export function init () {
    onFind(selectors.base, function (darkpageModal) {
        let modalInit = new Modal(darkpageModal);
        console.log("init", darkpageModal);

        try{
            if(!window.sessionStorage.getItem('darkpage')) {
                modalInit.show();
            }

            on('hidden.bs.modal', function () {
                window.sessionStorage.setItem('darkpage', true);
            }, darkpageModal);
        }catch(e){
            console.log(e);
        }
    });


}
