"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAll, find} from "@elements/dom-utils";
import asyncAppend from "@elements/async-append";
import {forceRecalculation} from "@elements/affix";
import axios from "axios";

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-load-cart',
    target: '.js-load-cart__target',
    loading: '.js-load-cart__loading',
    notifications: '.js-load-cart__notifications'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createCart(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    },document);
}

export function createCart(base, options, selectors) {
    let url = _config.loadCart;

    updateCart();

    on('click', function(){
        let pendingRequest;
        pendingRequest = axios({method: 'POST', url: url });

        asyncAppend({
            target: findAll(selectors.target),
            loading: findAll(selectors.loading),
            notifications: findAll(selectors.notifications),
            options: {
                allowScriptTags: true,
            }
        }, pendingRequest.then(function (x){
            if(x.data.cartItemsCounter){
                setCartCount(x.data.cartItemsCounter);
            }
            return x.data;
        }));
        return pendingRequest;
    }, base);
}

export function updateCart(selectors = defaultSelectors) {
    let url = _config.loadCart;

    let pendingRequest;
    pendingRequest = axios({method: 'POST', url: url });

    asyncAppend({
        target: findAll(selectors.target),
        loading: findAll(selectors.loading),
        notifications: findAll(selectors.notifications),
        options: {
            allowScriptTags: true,
        }
    }, pendingRequest.then(function (x){
        if(x.data.cartItemsCounter){
            setCartCount(x.data.cartItemsCounter);
        }
        return x.data;
    }));

    forceRecalculation();
    return pendingRequest;
}

export function setCartCount(count) {
    let cartCount = find('.js-load-cart__count');
    console.log(cartCount);
    console.log("CARTCOUNT FOUND");

    if(cartCount) {
        console.log("CARTCOUNT FOUND");
        cartCount.innerHTML = count;
    } else {
        console.warn("ERROR: .js-load-cart__count not found!");
    }
}