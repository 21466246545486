import { startStimulusApp } from '@symfony/stimulus-bridge';
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));

import { registerVueControllerComponents } from '@symfony/ux-vue';
registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// use for pinia store
import {createPinia} from 'pinia';
document.addEventListener('vue:before-mount', (event) => {
    const {
        app,
    } = event.detail;

    const pinia = createPinia()
    app.use(pinia);
});

import {onFind} from "@elements/init-modules-in-scope";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as imgScrollZoom from './img-scroll-zoom';
imgScrollZoom.init();

// Todo: Add common JS that is shared between all page types here

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: {event: 'false'}
});

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as affix from '@elements/affix';
affix.init({}, {
    base:'.js-affix',
    placeholder:'.js-affix__placeholder',
    container: '.js-affix__container'
});

import * as nav from './nav';
nav.init();

import * as cursor from './cursor';
cursor.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element)
});

import * as darkPage from './darkpage';
darkPage.init();

import * as loadCart from './load-cart';
loadCart.init();

import * as dynamicModal from './dynamic-modal';
dynamicModal.init();

import * as message from './message';
message.init();

import * as alertNotificationsExtended from './alert-notifications-extended';
alertNotificationsExtended.init();

import * as hashCash from './hash-cash';
hashCash.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init();

import * as numberCounterAnimations from './number-count-animation';
numberCounterAnimations.init();

import * as expandNavbarBackground from './navigation-expand-background';
expandNavbarBackground.init();

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as tracking from "@elements/tracking";

let options = { debug: false }; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as ajaxFormTracking from "./ajax-form-tracking";
ajaxFormTracking.init();

import * as trackingExtension from './tracking-extension';
trackingExtension.init();