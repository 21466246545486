import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {resultTracking} from "@elements/tracking";

export function init () {
    onFind(".js-ajax-form-tracking", function (form) {
        on('fetched.ajax-form', function (evt){
            let result = evt.detail;
            if (result){
                resultTracking(result);
            }else{
                console.warn('No Tracking Result');
            }
        }, form);
    })
}
