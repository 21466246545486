import { onFind } from '@elements/init-modules-in-scope'
import { on, find } from "@elements/dom-utils"

const selectors = {
    image: '.js-navigation-expand-background',
    button: '.js-navigation-expand-background-button'
}

export function init() {
    onFind(selectors.image, (image) => {
        const button = find(selectors.button)
        console.log("button: ", button)
        if(button === null || button === undefined) return

        calculateScaleTargets()
        on("resize", () => calculateScaleTargets(), window)

        function calculateScaleTargets() {
            setCSSVariable('--fill-screen-scale-x-target', Math.ceil(window.innerWidth / button.getBoundingClientRect().width))
            setCSSVariable('--fill-screen-scale-y-target', Math.ceil(window.innerHeight / button.getBoundingClientRect().height))
        }
    })
}

function setCSSVariable(variable, value) {
    document.querySelector(':root').style.setProperty(variable, value);
}
