import {onFind} from "@elements/init-modules-in-scope";
import {on, find, findIn, closest} from "@elements/dom-utils";
import {trackEvent} from "@elements/tracking/src/gtm";

export function init() {
    onFind('.js-tracking-extension--delivery', function (element) {
        let trackingKey = element.getAttribute(`data-delivery-tracking-key`);
        let trackingObject = _trackingData[trackingKey];
        let submitButton = find('.js-tracking-extension--delivery-submit', element);

        if(trackingObject) {
            on('click', function (evt) {
                let checkedRadio = findIn('.js-tracking-extension--delivery-radio:checked', element);
                if(checkedRadio){
                    let value = checkedRadio.value || "";
                    trackingObject.datalayer.delivery_option = value;
                }

                trackEvent(trackingObject, {debug: true});
            }, submitButton);
        } else {
            console.warn("Tracking object not found for key: " + trackingKey);
        }

    });
}