"use strict";

import {findIn,find,on,addClass, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-main-navbar', function (header) {
        const msg = findIn('.js-message', header);

        if (msg) {
            let msgHeight = msg.offsetHeight;
            let navOverlay = findIn('.js-nav__overlay--height', header);

            if (navOverlay) {
                navOverlay.style.height = 'calc(100dvh - ' + msgHeight + 'px)';
            }
        }
        else {
            resizeNavOverlay();
        }
    });

    function resizeNavOverlay() {
        let navOverlay = find('.js-nav__overlay--height');

        if (navOverlay) {
            navOverlay.style.height = '100dvh';
        }
    }

    onFind('.js-message', function (message) {
        let messageClose = findIn('.js-message__close', message);

        const messageCloseHandler = (evt) => {
            document.cookie = "message-closed=true; expires='';";
            addClass('d-none', message);

            resizeNavOverlay();
        };
        on('click', (evt) => messageCloseHandler(evt), messageClose);
    });
}